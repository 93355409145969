import(/* webpackMode: "eager", webpackExports: ["default"] */ "E:\\All-Projects\\2024\\portfolio-site\\components\\homepage\\hero-skills.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "E:\\All-Projects\\2024\\portfolio-site\\components\\homepage\\profile-image.jsx");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\blackjack.png");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\deepstash.png");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\dokumenhub.png");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\dragamine.jpg");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\hitech-controls.png");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\molequilizer.png");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\prisma-phones.png");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\snackmates-client.png");
;
import(/* webpackMode: "eager" */ "E:\\All-Projects\\2024\\portfolio-site\\public\\projects\\snackmates-server.png");
